
import { Options, Vue } from 'vue-class-component'
import { ObjectTemplate } from '@cybertale/interface'
import ListComponent from '@/components/ListComponent.vue'
@Options({
  components: { ListComponent },
  props: {
    placeholder: {
      type: String,
      default: 'group:"def" division:"default" entity:"example"'
    }
  }
})
export default class Search extends Vue {
  title = ''
  placeholder = 'group:"def" division:"default" entity:"example"'
  renderComponent = false
  objectTemplates!: ObjectTemplate[]
}
