import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-100"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapComponent = _resolveComponent("MapComponent")!
  const _component_WelcomeComponent = _resolveComponent("WelcomeComponent")!
  const _component_FirmSelectionComponent = _resolveComponent("FirmSelectionComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ((_ctx.$store.state.requiresAuth === 2))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MapComponent)
        ]))
      : _createCommentVNode("", true),
    ((_ctx.$store.state.requiresAuth === 1))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_WelcomeComponent)
        ]))
      : _createCommentVNode("", true),
    ((_ctx.$store.state.requiresAuth === 3))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_FirmSelectionComponent)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}