
import { Options, Vue } from 'vue-class-component'
import TableComponent from '@/components/TableComponent.vue' // @ is an alias to /src

@Options({
  components: {
    TableComponent
  }
})
export default class Show extends Vue {
}
