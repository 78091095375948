import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fe6dc48"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "navbar fixed-bottom navbar-light bg-light"
}
const _hoisted_2 = { class: "container-fluid justify-content-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.renderComponent)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("form", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectTemplates, (_objectTemplate, key, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponent(_objectTemplate.Region, _objectTemplate.ObjectEnum)), {
              key: `${ key }-${ index }`,
              object: _objectTemplate
            }, null, 8, ["object"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}