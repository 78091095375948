import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container",
  style: {"text-align":"center"}
}
const _hoisted_2 = { class: "input-group mb-3 col-lg-4" }
const _hoisted_3 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_4 = { class: "form-control" }
const _hoisted_5 = { class: "input-group mb-3 col-lg-4" }
const _hoisted_6 = {
  class: "input-group-text",
  id: "basic-addon2"
}
const _hoisted_7 = { class: "form-control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListComponent = _resolveComponent("ListComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.$t.aboutUser), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t.userName), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$store.state.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t.email), 1),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$store.state.email), 1)
    ]),
    _createVNode(_component_ListComponent, { "use-routes": true })
  ]))
}