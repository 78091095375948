import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fw-bold"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.msg.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.msg.title), 1))
      : _createCommentVNode("", true),
    (_ctx.msg.title)
      ? (_openBlock(), _createElementBlock("br", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.msg.info)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.msg.info), 1))
      : _createCommentVNode("", true)
  ], 64))
}