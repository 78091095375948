
import { Options, Vue } from 'vue-class-component'
import ListComponent from '@/components/ListComponent.vue'
import { $t } from '@geocms/localization'
// import createStore from '@/store/index'

@Options({
  computed: {
    $t () {
      return $t
    }
  },
  components: {
    ListComponent
  }
})
export default class AccountProfile extends Vue {
}
