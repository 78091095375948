
import { Options, Vue } from 'vue-class-component'
import { Manager } from '@/mechanics/placeholderMechanic'
import {
  ActionTypeEnum,
  MechanicAbstract,
  ObjectTemplate,
  ObjectType,
  ObjectTypeEnum,
  RegionEnum,
  RegionType,
  StatType,
  StatTypeEnum,
  SubObjectTypeEnum
} from '@cybertale/interface'

@Options({
  props: {
    entity: Array,
    object: ObjectTemplate,
    index: Number
  }
})
export default class ColumnButtonComponent extends Vue {
  mechanic: MechanicAbstract = new Manager.Mechanic.PlaceholderMechanic()
  statTypeEnum = StatTypeEnum
  objectTypeEnum = ObjectTypeEnum
  objectType = ObjectType
  object!: ObjectTemplate
  entity!: ObjectTemplate[]
  index!: number
  objectTemplates: ObjectTemplate[] = this.mechanic.InitSet(this.entity)
  renderComponent = false
  isSmallScreen = false

  mounted () {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  }

  beforeUnmount () {
    this.mechanic.UnsubscribeConditions()
    window.removeEventListener('resize', this.checkScreenSize)
  }

  checkScreenSize () {
    this.isSmallScreen = window.innerWidth < 768
  }

  getComponent (_regionEnum : number, _objectEnum: number) {
    return RegionType.RegionTypes[_regionEnum].ObjectTypes[_objectEnum].GetComponent()
  }
}
