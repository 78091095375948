import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = {
  class: "input-group-text",
  for: "button-addon2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.object.Stats[_ctx.statTypeEnum.Label].Data), 1),
      _createElementVNode("button", {
        class: "btn btn-outline-secondary",
        type: "button",
        id: "button-addon2",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.$t.edit), 1)
    ])
  ]))
}