import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-bs-target", "id"]
const _hoisted_2 = {
  id: "modal-form",
  class: "needs-validation",
  novalidate: ""
}
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "modal-dialog modal-xl" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "modal-body container-fluid" }
const _hoisted_10 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startMechanic())),
      type: "button",
      style: {"display":"none"},
      "data-bs-toggle": "modal",
      "data-bs-target": `${'#formModal'+_ctx.object.Stats[_ctx.statTypeEnum.Tag].Data}`,
      id: `${'formModalOpen'+_ctx.object.Stats[_ctx.statTypeEnum.Tag].Data}`
    }, null, 8, _hoisted_1),
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.test($event.target))),
        class: "modal fade",
        id: `${'formModal'+_ctx.object.Stats[_ctx.statTypeEnum.Tag].Data}`,
        tabindex: "-1",
        "aria-labelledby": "formModalLabel",
        "aria-hidden": "true"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                id: `${'formModalClose'+_ctx.object.Stats[_ctx.statTypeEnum.Tag].Data}`,
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              }, null, 8, _hoisted_7),
              _createElementVNode("button", {
                type: "button",
                hidden: "",
                class: "btn-close",
                id: `${'formModalSubmit'+_ctx.object.Stats[_ctx.statTypeEnum.Tag].Data}`,
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Loading, {
                active: _ctx.renderComponent,
                "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.renderComponent) = $event)),
                "can-cancel": false,
                "is-full-page": false
              }, null, 8, ["active"]),
              (!_ctx.reRenderToo)
                ? (_openBlock(), _createElementBlock("div", { key: _ctx.componentKey }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectTemplates, (_objectTemplate, key, index) => {
                      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponent(_objectTemplate.Region, _objectTemplate.ObjectEnum)), {
                        "page-refresh": _ctx.renderComponent,
                        rerender: _ctx.changeRender,
                        key: `${ key }-${ index }-${ _objectTemplate.Stats[_ctx.statTypeEnum.Tag].Data }`,
                        entity: _ctx.resolveEntities(_objectTemplate),
                        object: _objectTemplate
                      }, null, 8, ["page-refresh", "rerender", "entity", "object"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getSubmitButtonComponent()), { object: _ctx.submitButton }, null, 8, ["object"]))
            ])
          ])
        ])
      ], 8, _hoisted_3)
    ])
  ]))
}