import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Loading, {
      active: _ctx.renderComponent,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.renderComponent) = $event)),
      "can-cancel": false,
      "is-full-page": false
    }, null, 8, ["active"]),
    (!_ctx.reRenderToo)
      ? (_openBlock(), _createElementBlock("form", {
          key: _ctx.componentKey,
          class: "needs-validation",
          id: "classic-form",
          novalidate: ""
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectTemplates, (_objectTemplate, key, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponent(_objectTemplate.Region, _objectTemplate.ObjectEnum)), {
              rerender: _ctx.changeRender,
              "page-refresh": _ctx.renderComponent,
              key: `${ key }-${ index }-${ _objectTemplate.Stats[_ctx.statTypeEnum.Tag].Data }`,
              entity: _ctx.resolveEntities(_objectTemplate),
              object: _objectTemplate
            }, null, 8, ["rerender", "page-refresh", "entity", "object"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}