
import { Options, Vue } from 'vue-class-component'
import {
  ObjectTemplate,
  ObjectType,
  StatTypeEnum,
  ObjectTypeEnum,
  RegionType
} from '@cybertale/interface'
import router from '@/router'
import { Definitions } from '@geocms/components'
import { $t } from '@geocms/localization'
@Options({
  computed: {
    $t () {
      return $t
    }
  },
  props: {
    object: ObjectTemplate,
    index: Number
  }
})
export default class ECabinetColumnComponent extends Vue {
  statTypeEnum = StatTypeEnum
  objectTypeEnum = ObjectTypeEnum
  objectType = ObjectType
  object!: ObjectTemplate
  index!: number

  beforeUnmount () {
    // this.mechanic.UnsubscribeConditions()
  }

  edit () {
    router.push({
      name: Definitions.Entity.Edit,
      params: { id: this.object.Stats[this.statTypeEnum.Id].Data }
    })
  }

  getComponent (_regionEnum : number, _objectEnum: number) {
    return RegionType.RegionTypes[_regionEnum].ObjectTypes[_objectEnum].GetComponent()
  }
}
